"use strict";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as modGsap from "./_gsap";
import { initSpanWrap } from "./_spanWrap";
initSpanWrap();
modGsap.init({ scrollTrigger: true });

export const initScrollAnimation = () => {
  const targets = document.querySelectorAll(".js-scroll-animation");
  targets.forEach((target) => {
    ScrollTrigger.create({
      trigger: target,
      start: "top 75%",
      stagger: 0.1,
      once: true,
      onEnter: () => {
        target.classList.add("is-active");
      },
    });
  });
};
export const slideInLetters = () => {
  const slideInTargets = document.querySelectorAll(".js-slidein-text");
  for (const letter of slideInTargets) {
    letter.spans = letter.querySelectorAll("span");
  }
  const slideInLettersSet = {
    display: "inline-block",
    // overflow: 'hidden',
    autoAlpha: 0,
    y: "100%",
    duration: 1,
    ease: "power2.out",
  };
  const slideInLettersTo = {
    autoAlpha: 1,
    y: 0,
    stagger: 0.1,
  };
  slideInTargets.forEach((item, index) => {
    const child = item.getElementsByTagName("span");
    modGsap.gsap.set(child, slideInLettersSet);
    modGsap.gsap.to(child, {
      ...slideInLettersTo,
      scrollTrigger: {
        trigger: item,
        start: "top 85%",
        // once: true,
      },
    });
  });
};
export const sideInLetters = () => {
  const slideInTargets = document.querySelectorAll(".js-sidein-text");
  for (const letter of slideInTargets) {
    letter.spans = letter.querySelectorAll("span");
  }
  const slideInTargetsSet = {
    display: "inline-block",
    // overflow: 'hidden',
    autoAlpha: 0,
    filter: "blur(10px)",
    x: "100%",
    duration: 1,
    ease: "power2.out",
  };
  const slideInTargetsTo = {
    filter: "blur(0px)",
    autoAlpha: 1,
    x: 0,
    stagger: 0.06,
  };
  slideInTargets.forEach((item, index) => {
    const child = item.getElementsByTagName("span");
    modGsap.gsap.set(child, slideInTargetsSet);
    modGsap.gsap.to(child, {
      ...slideInTargetsTo,
      scrollTrigger: {
        trigger: item,
        start: "top 85%",
        // once: true,
      },
    });
  });
};
