"use strict";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { initSpanWrap } from "./_spanWrap";
initSpanWrap();

const activeClass = "is-active";

export { gsap };
export { ScrollTrigger };

export function init() {
  gsap.registerPlugin(ScrollTrigger);
}

// ランダムなdelayを付けたいときに
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

//- scrollTriggerでクラスを付与 (1度だけ実行)
export function onceAnimation(className = ".anim-once", viewportAnker = "75%") {
  const animOnce = document.querySelectorAll(className);
  animOnce.forEach((item) => {
    ScrollTrigger.create({
      trigger: item,
      start: "top " + viewportAnker,
      once: true,
      onEnter: () => {
        item.classList.add(activeClass);
      },
    });
  });
}

//- scrollTriggerでクラスを付与 (複数回実行)
export function repeatAnimation(
  className = ".anim-repeat",
  viewportAnker = "75%"
) {
  const animRepeat = document.querySelectorAll(className);
  animRepeat.forEach((item) => {
    ScrollTrigger.create({
      trigger: item,
      start: "top " + viewportAnker,
      toggleActions: "play reverse play reverse",
      toggleClass: { targets: item, className: activeClass },
    });
  });
}
