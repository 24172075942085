"use strict";

// ===========================================
//  variable / 変数宣言
// ===========================================
// アクティブなクラス
const IS_ACTIVE = "is-active";
const sections = modGsap.gsap.utils.toArray(".index-container-panel");
// const salesButton = document.querySelector(".index-floating");
const footer = document.querySelector(".index-footer");
const triggerItems = modGsap.gsap.utils.toArray(".anim-trigger");
const indexKvTl = modGsap.gsap.timeline();
const pageTitle = document.querySelector(".index-kv-copy");
const kvFigureUpper = document.querySelector(".main-kv-figure__upper");
const kvFigureUpperImage = document.querySelector(".main-kv-figure__upper img");
let horizontalScroll;

// ===========================================
//  library / ライブラリ
// ===========================================
import { mediaQuery } from "../utility/_variable";
import * as modGsap from "../utility/_gsap";
import { slideInLetters, sideInLetters } from "../utility/_scrollAnim";
modGsap.init({ scrollTrigger: true });
// modGsap.init();

// ===========================================
//  setup /
// ===========================================
const playVideos = () => {
  const videos = modGsap.gsap.utils.toArray(".index-figure-video");
  videos.forEach((video) => {
    modGsap.ScrollTrigger.create({
      trigger: video,
      start: "top 80%",
      end: "bottom 20%",
      onEnter: () => video.play(),
      // onEnterBack: () => video.play(),
      // onLeave: () => video.pause(),
      // onLeaveBack: () => video.pause(),
    });
  });
};
const listener = (event) => {
  if (event.matches) {
    // PC
    scrollHorizontal();
  } else {
    // SP
    if (horizontalScroll != null) horizontalScroll.scrollTrigger.kill();
    scrollVertical();
    playVideos();
    modGsap.onceAnimation();
    // stickeysalesButton();
  }
};
mediaQuery.addEventListener("change", listener);
listener(mediaQuery);

// ===========================================
//  function / 処理
// ===========================================
const KvAnim = () => {
  const kvTl = modGsap.gsap.timeline({
    defaults: {
      duration: 1.5,
    },
  });

  kvTl.fromTo(
    kvFigureUpper,
    {
      clipPath: "polygon(100% 100%, 0% 100%, 0% 100%, 100% 100%)",
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
      clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)",
      ease: "power2.inOut",
    },
    0
  );
  kvTl.from(
    kvFigureUpperImage,
    {
      filter: "blur(8px)",
      scaleY: 1.3,
      scaleX: 1.2,
    },
    0
  );
  kvTl.add(sideInLetters(pageTitle));
};

// フッター近くで追従ボタン非表示
function stickeysalesButton() {
  modGsap.ScrollTrigger.create({
    trigger: footer,
    start: "top bottom",
    toggleClass: { targets: salesButton, className: "is-hidden" },
    // markers: true,
  });
}

// 採用情報の写真が流れるセクション
function flowBlockLoop() {
  const flowBlocks = document.querySelectorAll(".index-recruit-flows");
  const updateAnimation = () => {
    flowBlocks.forEach((flowBlock) => {
      const flowItemWraps = Array.from(
        flowBlock.querySelectorAll(".flow-line")
      );

      flowItemWraps.forEach((wrap) => {
        const lines = wrap.querySelectorAll(".flow-line__image");
        const logoCount = lines.length;

        // アニメーション時間を計算
        // ロゴの数 * 1秒
        const wrapDuration = logoCount * 8;

        const resetWrap = () => {
          wrap.style.transform = "none";
        };

        if (modGsap.gsap.getTweensOf(wrap).length === 0) {
          modGsap.gsap.to(wrap, {
            duration: wrapDuration,
            repeat: -1,
            onRepeat: resetWrap,
            ease: "none",
            ...(window.innerWidth <= 680
              ? { xPercent: -100 }
              : { yPercent: -100 }),
          });
        } else {
          modGsap.gsap.to(wrap, {
            ...(window.innerWidth <= 680
              ? { xPercent: -100, yPercent: 0 }
              : { yPercent: -100, xPercent: 0 }),
          });
        }
      });
    });
  };
  updateAnimation();
  window.addEventListener("resize", updateAnimation);
}
// ==============================
// 横スクロール部分
// https://codepen.io/olig/pen/GRZORvX
// ==============================
function scrollHorizontal() {
  let maxWidth = 0;
  let getMaxWidth = () => {
    maxWidth = 0;
    sections.forEach((section) => {
      maxWidth += section.offsetWidth;
    });
  };
  getMaxWidth();
  modGsap.ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

  horizontalScroll = modGsap.gsap.to(sections, {
    x: () => `-${maxWidth - window.innerWidth}`,
    ease: "none",
    scrollTrigger: {
      trigger: ".index-container",
      pin: true,
      anticipatePin: 1,
      scrub: 1,
      end: () => `+=${maxWidth}`,
      invalidateOnRefresh: true,
    },
  });

  const headerInfoObserver = new IntersectionObserver(
    (entries) => {
      const floating = document.querySelector(".header-info");
      entries.forEach((entry) => {
        floating.classList.toggle("is-hidden", !entry.isIntersecting);
      });
    },
    { threshold: 0 }
  );

  const panel = document.querySelector(".index-kv-copy");
  // 各パネルを観察対象に追加
  if (panel) {
    headerInfoObserver.observe(panel);
  }
  // panels.forEach((panel) => {
  //   headerInfoObserver.observe(panel);
  // });

  // .index-figure-videoの表示を監視し、動画を一度だけ再生するIntersectionObserverを作成
  const videoObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const video = entry.target;
        const index = Array.from(allVideos).indexOf(video);
        // console.log(
        //   `Video ${index} intersecting: ${entry.isIntersecting}, src: ${video.src}, hasPlayed: ${video.hasPlayed}, currentTime: ${video.currentTime}, duration: ${video.duration}`
        // );
        if (entry.isIntersecting && !video.hasPlayed) {
          // ビデオの再生を少し遅延させる
          setTimeout(() => {
            video.currentTime = 0;
            video
              .play()
              .then(() => {
                // console.log(`Video ${index} started playing:`, video.src);
                video.hasPlayed = true;
              })
              .catch((error) => {
                // console.error(
                //   `Error playing video ${index}:`,
                //   error,
                //   video.src
                // );
              });
          }, 100);
        } else if (!entry.isIntersecting && !video.paused) {
          video.pause();
          // console.log(`Video ${index} paused:`, video.src);
        }
      });
    },
    { threshold: 0.05, rootMargin: "0px 0px -5% 0px" }
  );

  // すべての.index-figure-video要素を取得し、監視対象に追加
  const allVideos = document.querySelectorAll("video.index-figure-video");
  allVideos.forEach((video, index) => {
    video.muted = true;
    video.playsInline = true;
    video.loop = false;
    video.hasPlayed = false;

    // ビデオの読み込み状態をチェック
    // console.log(`Video ${index} initial state:`, video.src);
    // console.log(`- readyState: ${video.readyState}`);
    // console.log(`- networkState: ${video.networkState}`);
    // console.log(`- error: ${video.error}`);
    // console.log(`- duration: ${video.duration}`);

    // video.addEventListener("loadedmetadata", () => {
    //   console.log(`Video ${index} metadata loaded:`, video.src);
    //   console.log(`- duration: ${video.duration}`);
    // });

    videoObserver.observe(video);
    // console.log(`Video ${index} added to observer:`, video.src);
  });

  // エラーハンドリングの改善
  window.addEventListener("unhandledrejection", function (event) {
    // console.error(
    //   "Unhandled rejection (promise: ",
    //   event.promise,
    //   ", reason: ",
    //   event.reason,
    //   ")."
    // );
  });
  sections.forEach((sct, i) => {
    modGsap.ScrollTrigger.create({
      trigger: sct,
      start: () =>
        "top top-=" +
        (sct.offsetLeft - window.innerWidth / 2) *
          (maxWidth / (maxWidth - window.innerWidth)) *
          0.5,
      end: () =>
        "+=" + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
      onEnter: () => {
        sct.classList.add(IS_ACTIVE);
      },
      onLeave: () => {
        sct.classList.remove(IS_ACTIVE);
      },
    });
  });

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("is-visible", entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target);
      });
    },
    { threshold: 0.5 }
  );
  triggerItems.forEach((triggerItem) => {
    observer.observe(triggerItem);
  });
}
// ページロード完了時にビデオの状態を再チェック
// window.addEventListener("load", () => {
//   document
//     .querySelectorAll("video.index-figure-video")
//     .forEach((video, index) => {
//       console.log(`Video ${index} state after page load:`);
//       console.log("- src:", video.src);
//       console.log("- readyState:", video.readyState);
//       console.log("- networkState:", video.networkState);
//       console.log("- error:", video.error);
//     });
// });
function scrollVertical() {
  sections.forEach((sct, i) => {
    modGsap.ScrollTrigger.create({
      trigger: sct,
      start: "center bottom",
      end: "top bottom",
    });
  });
}

// ===========================================
//  init / 初期化
// ===========================================
const init = () => {
  // let scrollTween = modGsap.gsap.to(sections, {
  //   xPercent: -100 * (sections.length - 1),
  //   ease: "none",
  // });
  // const images = modGsap.gsap.utils.toArray(".index-parallax-image");
  // images.forEach((img, i) => {
  //   modGsap.gsap.to(img, {
  //     scrollTrigger: {
  //       trigger: img,
  //       start: "top center",
  //       end: "bottom center",
  //       horizontal: true,
  //       markers: true,
  //       scrub: 0.3,
  //       containerAnimation: horizontalScroll,
  //     },
  //     x: -80,
  //     ease: "none",
  //   });
  // });
  KvAnim();
  flowBlockLoop();
  // stickeysalesButton();
  // indexVerticalScroll();
};
// ===========================================
//  load / 読み込み
// ===========================================

window.addEventListener("DOMContentLoaded", () => {
  init();
});
