export const DESIGN_WIDTHS = {
  mobile: 768,
  desktop: 1360,
};

const { mobile, desktop } = DESIGN_WIDTHS;

export const BREAKPOINTS = {
  small: mobile * 0.5 * 1,
  medium: desktop * 0.5,
  large: desktop,
  xlarge: desktop * 1.25,
};
export const mediaQuery = window.matchMedia(
  "(min-width:" + BREAKPOINTS.medium + "px)"
);
